const renderErrors = (elementId, message) => {
  const $input = $(`form #${elementId}`);

  if ($input.next('.js-error-helper').length === 0) {
    $input.after('<div class="js-error-helper error-helper has-error"></div>');
  }

  $input.next('.js-error-helper').html(message);
  $input.addClass('is-invalid');

  $input.one('focus change input paste', () => {
    $input.removeClass('is-invalid');
    $input.next('.js-error-helper').html('');
  });
};

const removeAttachment = (idx, msg) => {
  if (confirm(msg)) {
    $(`.js-file-item[data-index=${idx}]`).remove();
  }
};

const bindEmbedder = ($embedder, { CSRF_PARAM, CSRF_TOKEN } = {}) => {
  if (!$embedder.length) {
    return null;
  }

  const $submit = $embedder.parents('form').find('input[type="submit"], button[type="submit"]');
  $submit.data('buttonText', $submit.val());

  $embedder.on('change', (e) => {
    const $ui = $(e.target);
    const value = e.target.value;

    if (!value) {
      return true;
    }

    $ui.trigger('blur').attr('disabled', true);

    $submit.val($submit.data('disableWith'))
    $submit.attr('disabled', true);

    $.ajax({
      url: '/previews/embed',
      method: 'POST',
      data: {
        url: value,
        index: $('.js-file-item').length,
        [CSRF_PARAM]: CSRF_TOKEN,
      },
      success: () => {
        $ui.val('');
        return true;
      },
      complete: () => {
        $ui.attr('disabled', false);
        $submit.val($submit.data('buttonText'))
        $submit.attr('disabled', false);
        return true;
      },
    });
  });
};

const bindUploader = ($uploader, { CSRF_PARAM, CSRF_TOKEN } = {}) => {
  if (!$uploader.length) {
    return null;
  }

  const $submit = $uploader.parents('form').find('input[type="submit"], button[type="submit"]');
  $submit.data('buttonText', $submit.val())

  $uploader.on('change', (e) => {
    if (!e.target.files.length) {
      return false;
    }

    const $ui = $(e.target);
    const formData = new FormData();

    $ui.trigger('blur').attr('disabled', true);
    $submit.val($submit.data('disableWith'));
    $submit.attr('disabled', true)

    formData.append('file', e.target.files[0]);
    formData.append('index', $('.js-file-item').length);
    formData.append(CSRF_PARAM, CSRF_TOKEN);

    $.ajax({
      url: '/previews/file',
      method: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: () => {
        $ui.val('');
        return true;
      },
      complete: (response) => {
        $ui.attr('disabled', false);
        $submit.attr('disabled', false);
        $submit.val($submit.data('buttonText'));
        return true;
      },
    })
  });
};

const App = {
  renderErrors,
  removeAttachment,
  bindEmbedder,
  bindUploader,
};

export default App;
