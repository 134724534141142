/* eslint no-console:0 */
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import App from './global-app';

Turbolinks.BrowserAdapter.prototype.reload = function () {
  window.scrollTo(0, 0);
  window.location.reload();
};

Rails.start();
Turbolinks.start();

const yAxisOptions = {
  ticks: { type: 'linear', stepSize: 1, beginAtZero: true, min: 0 },
};

const xAxisOptions = {
  type: 'linear',
  ticks: { beginAtZero: true, min: 0, max: 10, callback: value => ((value * 10) + '%') },
};

const defaultGraphOptions = {
  responsive: true,
  legend: { display: false },
  scales: {
    yAxes: [yAxisOptions],
    xAxes: [xAxisOptions],
  },
};

const linearDatasetOption = {
  fill: false,
  borderWidth: 1,
  backgroundColor: 'rgba(255, 99, 132, 0.2)',
  borderColor: 'rgba(75, 192, 192, 1)',
};

const barDatasetOption = {
  fill: true,
  borderWidth: 1,
  backgroundColor:[
    "rgb(255, 99, 132)",
    "rgb(75, 192, 192)",
    "rgb(255, 205, 86)",
    "rgb(201, 3, 207)",
    "rgb(54, 162, 235)",
    "rgb(255, 99, 132)",
    "rgb(75, 192, 192)",
    "rgb(155, 5, 86)",
    "rgb(01, 203, 207)",
    "rgb(54, 162, 235)",
    "rgb(154, 62, 35)"],
  borderColor: 'rgba(255, 159, 64, 0.0)',
};

const onLoad = () => {
  const CSRF_TOKEN = $('meta[name=csrf-token]').attr('content');
  const CSRF_PARAM = $('meta[name=csrf-param]').attr('content');
  const $sliders = $('.js-range-slider');
  const $forms = $('form');
  const $window = $(window);
  const $header = $('header');
  const $probabilityCanvas = $('#probability-rating-canvas');
  const $impactCanvas = $('#impact-rating-canvas');
  const $embedder = $('input.js-url-embed');
  const $uploader = $('input.js-file-upload');
  const $fieldsWithValue = $('input[value]')

  $window.scroll(() => {
    if ($window.scrollTop() > 250) {
      $header.addClass('sticky');
    } else {
      $header.removeClass('sticky');
    }
  });

  if ($sliders.length > 0)  {
    $sliders.each((i, ui) => {
      const $ui = $(ui);

      const sliderConfig = {
        type: "single",
        min: $ui.data('min') || 0,
        max: $ui.data('max') || 10,
        from: 0,
        to: 0,
        grid: true,
        grid_num: $ui.data('max') || 10,
        hide_from_to: true,
        hide_min_max: true,
        step: 0.1
      };

      $ui.ionRangeSlider(sliderConfig);
    });
  }

  if ($probabilityCanvas.length > 0) {
    const probabilityStats = $probabilityCanvas.data('stats') || [];
    const ctx = $probabilityCanvas.get(0).getContext('2d');
    const probabilityChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: $probabilityCanvas.data('label'),
          ...linearDatasetOption,
          data: probabilityStats,
        }],
      },
      options: defaultGraphOptions,
    });
  }

  if ($impactCanvas.length > 0) {
    const impactStats = $impactCanvas.data('stats') || [];
    const ctx = $impactCanvas.get(0).getContext('2d');
    const impactChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: $impactCanvas.data('label'),
          ...linearDatasetOption,
          data: impactStats,
        }],
      },
      options: defaultGraphOptions,
    });
  }

  if ($forms.length) {
    const $inputs = $forms.find('input, textarea');

    $inputs.on('keyup blur focus', function (e) {
      const $ui = $(this);
      const $label = $ui.prev('label');
      const value = $ui.val();

      if (e.type === 'keyup' || e.type === 'input' || e.type === 'focus') {
        if (value === '') {
          $label.removeClass('active highlight');
        } else {
          $label.addClass('active highlight');
        }
      } else if (e.type === 'blur') {
        if (value === '' ) {
          $label.removeClass('active highlight');
        } else {
          $label.removeClass('highlight');
        }
      }
    });
  }

  App.bindEmbedder($embedder, { CSRF_PARAM, CSRF_TOKEN });
  App.bindUploader($uploader, { CSRF_PARAM, CSRF_TOKEN });

  // make sure the labels display belowe the field for fields ith value
  $fieldsWithValue.trigger('keyup').blur();
};

$(document).on('turbolinks:load', onLoad);

window.HorizonScanningApp = App;
